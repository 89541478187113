import React from 'react';
import Script from 'next/script';

import { styled } from '@mui/material/styles';

import Box from '@mui/material/Box';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import sidekick from '@last-rev/contentful-sidekick-util';

import Grid from '../Grid';
import ErrorBoundary from '../ErrorBoundary';
import ContentModule from '../ContentModule';
import Background from '../Background';
import { useJsonLd } from '../JsonLd';

import type { AccordionProps, AccordionOwnerState } from './Accordion.types';

const Accordion = (props: AccordionProps) => {
  const ownerState = { ...props };
  const { addJsonLdItems } = useJsonLd();

  const { actions, backgroundColor, id, items, variant, sidekickLookup, introText } = props;

  const [expanded, setExpanded] = React.useState<string | false>(() => {
    const [item] = items || [];
    return `${id}-accordion-panel-${item?.id}-0`;
  });

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  React.useEffect(() => {
    const jsonLdItems = items?.map((item) => item?.jsonLd)?.filter(Boolean);
    addJsonLdItems(jsonLdItems);
  }, [items]);

  return (
    <ErrorBoundary>
      <Root
        {...sidekick(sidekickLookup)}
        ownerState={ownerState}
        data-testid={`Accordion-${variant}`}>
        <AccordionBackground backgroundColor={backgroundColor} testId="Accordion-background" />

        {!!introText && (
          <IntroTextGrid ownerState={ownerState}>
            <IntroText
              ownerState={ownerState}
              {...sidekick(sidekickLookup, 'introText')}
              {...introText}
              textVariant="introText"
            />
          </IntroTextGrid>
        )}

        {!!items?.length && (
          <ContentOuterGrid ownerState={ownerState}>
            {items?.map(
              (
                item: any,
                index: number // TODO: Fix type
              ) => (
                <React.Fragment key={`${id}-accordion-panel-${item?.id}-${index}`}>
                  <AccordionItem
                    disableGutters
                    expanded={expanded === `${id}-accordion-panel-${item?.id}-${index}`}
                    onChange={handleChange(`${id}-accordion-panel-${item?.id}-${index}`)}
                    ownerState={ownerState}>
                    <SummaryWrap
                      // aria-controls="panel1d-content"
                      id={item?.id}
                      ownerState={ownerState}
                      expandIcon={<ExpandMoreIcon />}>
                      <Summary __typename="RichText" body={item.titleRTE} ownerState={ownerState} />
                    </SummaryWrap>
                    <DetailsWrap ownerState={ownerState}>
                      {item.bodyRTE ? (
                        <Details
                          __typename="RichText"
                          body={item.bodyRTE}
                          ownerState={ownerState}
                        />
                      ) : null}
                      {!item.body && item.content ? (
                        <Details {...item.content} ownerState={ownerState} />
                      ) : null}
                    </DetailsWrap>
                  </AccordionItem>
                </React.Fragment>
              )
            )}
            {!!actions?.length && (
              <ActionsWrap
                {...sidekick(sidekickLookup, 'actions')}
                data-testid="Accordion-actions"
                ownerState={ownerState}>
                {actions.map((action) => (
                  <Action
                    ownerState={ownerState}
                    key={action?.id}
                    {...action}
                    color={action?.color ?? 'primary'}
                  />
                ))}
              </ActionsWrap>
            )}
          </ContentOuterGrid>
        )}
      </Root>
    </ErrorBoundary>
  );
};

const Root = styled(Box, {
  name: 'Accordion',
  slot: 'Root',
  overridesResolver: (_, styles) => [styles.root]
})<{ ownerState: AccordionOwnerState }>``;

const AccordionBackground = styled(Background, {
  name: 'Tabs',
  slot: 'AccordionBackground',
  overridesResolver: (_, styles) => [styles.background]
})<{}>``;

const ContentOuterGrid = styled(Grid, {
  name: 'Accordion',
  slot: 'ContentOuterGrid',
  overridesResolver: (_, styles) => [styles.contentOuterGrid]
})<{ ownerState: AccordionOwnerState }>``;

const IntroTextGrid = styled(Grid, {
  name: 'Accordion',
  slot: 'IntroTextGrid',
  overridesResolver: (_, styles) => [styles.introTextGrid]
})<{ ownerState: AccordionOwnerState }>``;

const IntroText = styled(ContentModule, {
  name: 'Accordion',
  slot: 'IntroText',
  overridesResolver: (_, styles) => [styles.introText]
})<{ ownerState: AccordionOwnerState }>``;

const AccordionItem = styled(MuiAccordion, {
  name: 'Accordion',
  slot: 'AccordionItem',
  overridesResolver: (_, styles) => [styles.accordionItem]
})<{ ownerState: AccordionOwnerState }>``;

const SummaryWrap = styled(MuiAccordionSummary, {
  name: 'Accordion',
  slot: 'SummaryWrap',
  overridesResolver: (_, styles) => [styles.summaryWrap]
})<{ ownerState: AccordionOwnerState }>``;

const Summary = styled(ContentModule, {
  name: 'Accordion',
  slot: 'Summary',
  overridesResolver: (_, styles) => [styles.summary]
})<{ ownerState: AccordionOwnerState }>``;

const DetailsWrap = styled(MuiAccordionDetails, {
  name: 'Accordion',
  slot: 'DetailsWrap',
  overridesResolver: (_, styles) => [styles.detailsWrap]
})<{ ownerState: AccordionOwnerState }>``;

const Details = styled(ContentModule, {
  name: 'Accordion',
  slot: 'Details',
  overridesResolver: (_, styles) => [styles.details]
})<{ ownerState: AccordionOwnerState }>``;

const ActionsWrap = styled(Box, {
  name: 'Accordion',
  slot: 'ActionsWrap',
  overridesResolver: (_, styles) => [styles.actionsWrap]
})<{ ownerState: AccordionOwnerState }>``;

const Action = styled(ContentModule, {
  name: 'Accordion',
  slot: 'Action',
  overridesResolver: (_, styles) => [styles.action]
})<{ ownerState: AccordionOwnerState }>``;

export default Accordion;
